import { data, API } from '@/shared';

export default {
  namespaced: true,
  state: {
    ads: null,
    ad: null,
    total: null,
    recommend: null,
    adtips: [
      {
    id: "carib",
    site: {
      en: 'Caribbeancom',
      ja: 'カリビアンコム',
      url: 'caribbeancom.com'
    },
    zip: {
      file_name: 'gallery.zip',
      sample_url: 'https://www.caribbeancompr.com/moviepages/113019_002/<span class="red-txt">index.html</span>',
      url: 'https://www.caribbeancompr.com/moviepages/113019_002/<span class="red-txt">images/gallery.zip</span>'
    },
    mov: {
      url: 'mov_carib.jpg',
      txt_en: 'Depending on the work, there are both uncensored and censored (with mosaic) versions available.',
      txt_ja: '作品によって、無修正の場合とモザイク入りの場合があります。',
      bt:'1'
    }
  },
  {
    id: "caribpr",
    site: {
      en: 'CaribbeancomPREMIUM',
      ja: 'カリビアンコムプレミアム',
      url: 'caribbeancompr.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_caribpr.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'1'
    },
  },
  {
    id: "ippon",
    site: {
      en: '1Pondo',
      ja: '一本道',
      url: '1pondo.tv'
    },
    zip: {
      file_name: 'gallery.zip',
      sample_url: 'https://www.1pondo.tv/movies/041918_673/',
      url: 'https://www.1pondo.tv/<span class="red-txt">assets/sample</span>/041918_673/<span class="red-txt">gallery.zip</span>'
    },
    mov: {
      url: 'mov_1pon.jpg',
      txt_en: 'Sample videos available for works released on or after March 16, 2017',
      txt_ja: '2017年3月16日配信以降の作品からサンプル動画あり',
      bt:'1'
    },
  }, {
    id: "tenmusu",
    site: {
      en: '10musume',
      ja: '天然むすめ',
      url: '10musume.com'
    },
    zip: {
      file_name: 'gallery.zip',
      sample_url: 'https://www.10musume.com/movies/120517_01/',
      url: 'https://www.10musume.com/<span class="red-txt">assets/sample</span>/120517_01/<span class="red-txt">gallery.zip</span>'
    },
    mov: {
      url: 'mov_10musu.jpg',
      txt_en: 'release after March 11, 2017',
      txt_ja: '2017年3月11日配信以降配信',
      bt:'1'
    },
  }, {
    id: "paco",
    site: {
      en: 'PacoPacoMama',
      ja: 'パコパコママ',
      url: 'pacopacomama.com'
    },
    zip: {
      file_name: 'gallery.zip',
      sample_url: 'https://www.pacopacomama.com/movies/021621_435/',
      url: 'https://www.pacopacomama.com/<span class="red-txt">assets/sample</span>/021621_435/<span class="red-txt">gallery.zip</span>'
    },
    mov: {
      url: 'mov_paco.jpg',
      txt_en: 'release after March 14, 2017',
      txt_ja: '2017年3月14日配信以降配信',
      bt:'1'
    },
  }, {
    id: "hey",
    site: {
      en: 'HeyDouga',
      ja: 'Hey動画',
      url: 'heydouga.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_hey.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    },
  },
  {
    id: "heyzo",
    site: {
      en: 'HEYZO',
      ja: 'HEYZO',
      url: 'heyzo.com'
    },
    zip: {
      file_name: 'gallery.zip',
      sample_url: 'https://www.heyzo.com/moviepages/1622/<span class="red-txt">index.html</span>',
      url: 'https://www.heyzo.com/moviepages/1622/<span class="red-txt">gallery.zip</span>'
    },
    mov: {
      url: 'mov_heyzo.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    },
  }, {
    id: "kin8",
    site: {
      en: 'kin8tengoku',
      ja: '金髪天國',
      url: 'kin8tengoku.com'
    },
    zip: {
      file_name: 'gallery.zip',
      sample_url: 'https://www.kin8tengoku.com/moviepages/1818/<span class="red-txt">index.html</span>',
      url: 'https://www.kin8tengoku.com/moviepages/1818/<span class="red-txt">gallery.zip</span>',
      zip: ''
    },
    mov: {
      url: 'mov_kin8.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    },
  }, {
    id: "nyo",
    site: {
      en: 'Nyoshin',
      ja: '女体のしんぴ',
      url: 'nyoshin.com'
    },
    zip: {
      file_name: 'index.zip',
      sample_url: 'https://www.nyoshin.com/moviepages/n1576/<span class="red-txt">index.html</span>',
      url: 'https://www.nyoshin.com/moviepages/n1576/<span class="red-txt">index.zip</span>'
    },
    mov: {
      url: 'mov_nyo.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    },
  }, {
    id: "nozox",
    site: {
      en: 'NOZOX',
      ja: 'NOZOX',
      url: 'nozox.com'
    },
    zip: {
      file_name: 'gallery.zip',
      sample_url: '',
      url: 'https://www.nozox.com/images/gallery.zip'
    },
    mov: {
      url: '',
      txt_en: '',
      txt_ja: '',
      bt:''
    },
  }, {
    id: "h4610",
    site: {
      en: 'H4610',
      ja: 'エッチな4610',
      url: 'h4610.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_h4610.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    }
  },
  {
    id: "h0930",
    site: {
      en: 'H0930',
      ja: 'エッチな0930',
      url: 'h0930.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_h0930.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    }
  },
  {
    id: "h0930w",
    site: {
      en: 'H0930World',
      ja: 'エッチな0930WORLD',
      url: 'h0930world.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_h0930w.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    }
  },
  {
    id: "c0930",
    site: {
      en: 'C0930',
      ja: '人妻斬り',
      url: 'c0930.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_c0930.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    }
  },
  {
    id: "h0230",
    site: {
      en: 'H0230',
      ja: 'エッチな0230',
      url: 'h0230.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_h0230.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    }
  },
  {
    id: "unko",
    site: {
      en: 'UNKOTARE',
      ja: 'うんこたれ',
      url: 'unkotare.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_unko.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    }
  },
  {
    id: "3d",
    site: {
      en: '3D-EROS.NET',
      ja: '3D-EROS.NET',
      url: '3d-eros.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_3d.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    }
  },
  {
    id: "tousatsudou",
    site: {
      en: 'Tousatsudou',
      ja: '盗撮道',
      url: 'tousatsudou.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_tousatu.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    }
  },
  {
    id: "pikkur",
    site: {
      en: 'Pikkur',
      ja: 'Pikkur',
      url: 'pikkur.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_pikkur.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    }
  },
  {
    id: "javholic",
    site: {
      en: 'Javholic',
      ja: 'Javholic',
      url: 'javholic.com'
    },
    zip: {
      file_name: '',
      sample_url: '',
      url: ''
    },
    mov: {
      url: 'mov_javholic.jpg',
      txt_en: '',
      txt_ja: '',
      bt:'2'
    }
  }
    ]
  },
  getters: {
    getAdTipsData: state => state.adtipsData
  },
  mutations: {
    updateTotal(state, total) {
      state.total = total;
    },
    updateAds(state, ads) {
      state.ads = ads;
    },
    updateAd(state, ad) {
      state.ad = ad;
    },
    updateRecommend(state, recommend) {
      state.recommend = recommend;
    },
    resetAd(state) {
      state.ad= null
    },
    resetAds(state) {
      state.ads= null
    },
    SET_ADS(state, ads) {
      state.ads = ads;
    },
    CLEAR_ADS(state) {
      state.ads = null;
      state.total = null;
    },
  },
  actions: {
    async fetchAds({ commit }, siteId) {
      try {
        const response = await data.getData(`${API}/banners`, { site_id: siteId });
        commit('SET_ADS', response.allbanner);
        commit('updateTotal', response.total);
      } catch (error) {
        console.error('Error fetching ads:', error);
        // エラー処理を追加することをお勧めします
      }
    },
    clearAds({ commit }) {
      commit('CLEAR_ADS');
    },
    //ads?lang=&category=&genre=&sortby=&page=&limit=
    async getAdsAction({ commit }, params) {
      console.log(params)
      const ads = await data.getData(`${API}/banners`, params);
      const total = ads.total;
      commit("updateAds", ads);
      commit("updateTotal", total);
      return ads
    },
    async getAdAction({ commit }, params) {
      console.log(params)
      const ad = await data.getData(`${API}/banner/${params.id}`, {
        site_id: params.siteid,
        lang: params.lang});
      commit("updateAd", ad[0]);
      return ad[0]
    },
    // /api/banners?lang=ja&recommended=1&sortby=width_asc
    // recommend only => recommended=1
    // sort options => width_asc, width_desc, popularity_asc, popularity_desc, banner_id_asc, banner_id_desc
    async getRecommendAction({ commit }, lang) {
      let params = {
        lang:lang,
        recommend: 1,
        sortby:'popularity_asc'
      }
      const recommend = await data.getData(`${API}/banners/`, params);
      commit("updateRecommend", recommend.recommended);
    },
    resetAd({ commit }) {
      commit("resetAd");
    },
    resetAds({ commit }) {
      commit("resetAds");
    },
    resetAdsState({ commit }) {
      commit('SET_ADS', { total: 0, allbanner: [] });
    }
  }
}